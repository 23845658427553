<style lang="scss" scoped>
.common-list-search {
    &::v-deep {
        .list-search-before .el-form-item.el-form-item--small {
            width: auto;
        }
    }
}
</style>
<template>
    <el-main class="com-eTrip-section">
        <comListSearch>
            <div slot="list-search-before">
                <el-form inline>
                    <el-form-item>
                        <el-radio-group v-model="form.sourceType">
                            <el-radio-button>全部</el-radio-button>
                            <el-radio-button :label="0">900</el-radio-button>
                            <el-radio-button :label="1">南航</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="订单号" v-model="form.orderNo" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after">
                <el-button type="primary" plain :loading="loading" @click="reset()">重置</el-button>
                <el-button type="primary" :loading="loading" @click="search()">搜索</el-button>
            </div>
        </comListSearch>
        <div class="eTrip-section-cont">
            <el-table :data="tabData.data" border>
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="sourceType" label="评价来源" width="90" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.sourceType === 1 ? '南航' : '900' }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderNo" label="订单号" width="100" align="center"></el-table-column>
                <el-table-column prop="thirdOrderId" label="第三方订单号" width="110" align="center"></el-table-column>
                <el-table-column prop="driverId" label="司机ID" width="100" align="center"></el-table-column>
                <el-table-column prop="" label="车型" width="100" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.carType | seatModelDesc }}
                    </template>
                </el-table-column>
                <el-table-column label="评分" prop="evaluationLevel" align="center" width="100"></el-table-column>
                <el-table-column prop="evaluation" label="评价内容"></el-table-column>
                <el-table-column prop="" label="评价时间" width="160">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | dateCus }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-footer style="background-color: #fff;">
            <div style="display: flex;justify-content: space-between;align-items: center;height: 100%">
                <div style="display: flex;align-items: center" v-if="evaluationCarTypeAvgNum">
                    <h5 class="font_s16">南航平均分</h5>
                    <span class="ml10" v-for="vm in evaluationCarTypeAvgNum" :key="vm.car_type">
                            {{ Number(vm.car_type) | seatModelDesc }}：{{ vm.avg }}
                        </span>
                    <el-button size="mini" type="primary" class="ml10" :loading="getEvaluationCarTypeAvgNumLoading"
                               @click="getEvaluationCarTypeAvgNum">
                        <i class="el-icon-refresh"></i>刷新
                    </el-button>
                </div>
                <el-pagination class="pagination-info" @current-change="handlePageChange" :current-page="form.pageIndex"
                               :page-size="form.pageSize" layout="total, prev, pager, next"
                               :total="tabData.total"></el-pagination>
            </div>
        </el-footer>
    </el-main>
</template>

<script type="text/ecmascript-6">
import comListSearch from "@/www/components/comListSearch/index.vue";
import {getEvaluationCarTypeAvgNumApi, getOrderEvaluationRecordListApi} from "@/www/urls/orderUrls";
import {combinationForm} from "@/common/js/common";

export default {
    name: 'orderEvaluation',
    //定义模版数据
    data() {
        return {
            loading: false,
            form: {
                pageSize: 10,
                pageIndex: 1,
                sourceType: undefined
            },
            tabData: {
                data: [],
                total: 0
            },
            evaluationCarTypeAvgNum: null,
            getEvaluationCarTypeAvgNumLoading: false,
        }
    },
    components: {comListSearch},
    mounted() {
        this.search()
        this.getEvaluationCarTypeAvgNum();
    },
    methods: {
        async getEvaluationCarTypeAvgNum() {
            this.getEvaluationCarTypeAvgNumLoading = true
            try {
                const ret = await getEvaluationCarTypeAvgNumApi()
                if (ret.success && ret.data) {
                    this.evaluationCarTypeAvgNum = ret.data
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.getEvaluationCarTypeAvgNumLoading = false
            }
        },
        async search() {
            try {
                this.loading = true
                const ret = await getOrderEvaluationRecordListApi(combinationForm(this.form))
                if (ret.success) {
                    this.tabData = ret.data
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        reset() {
            this.form.pageIndex = 1;
            this.form.orderNo = undefined;
            this.form.sourceType = undefined;
            this.form.orderNo = undefined;
            this.search()
        },
        handlePageChange(page) {
            this.form.pageIndex = page
            this.search();
        },
        next(record, type) {
            this.$router.push(`/orderComplaintsDetail?id=${record.id}&type=${type}`)
        }
    }

}
</script>
